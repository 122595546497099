<template>
  <v-app-bar flat class="app-header-bar">
    <AppLogo />
    <v-btn color="app-header-bar-text app-header-bar--text" text :to="{ name: 'profileHomePage' }" class="ml-2">
      Home
    </v-btn>
  </v-app-bar>
</template>

<script>
import AppLogo from "@/components/common/AppLogo.vue";
import {mapGetters} from "vuex";

export default {
  name: "UnauthenticatedAppHeader",
  components: {AppLogo},
  computed: {
    ...mapGetters(["isStateUserValid"]),
  },
};
</script>
