import store from "@/store";

export default [
  {
    path: "/home",
    name: "home",
    meta: {
      isLoggedOut: true,
      tracking: {
        sections: ["home"]
      }
    },
    component: () => {
      const appConfig = store.getters.getAppConfiguration;

      if (appConfig.instanceKey === "pluggedin")
        return import(/* webpackChunkName: "authentication" */ "@/modules/Public/pages/PluggedIn");

      return import(/* webpackChunkName: "authentication" */ "@/modules/Public/pages/Home");
    }
  },
  {
    name: "privacyPage",
    meta: {
      ignoreAuth: true,
      tracking: {
        sections: ["privacy"]
      }
    },
    path: "/privacy",
    component: () =>
      import(/* webpackChunkName: "authentication" */ "@/modules/Public/pages/PrivacyPage"),
  },
  {
    name: "termsPage",
    meta: {
      ignoreAuth: true,
      tracking: {
        sections: ["terms"]
      }
    },
    path: "/terms",
    component: () =>
      import(/* webpackChunkName: "authentication" */ "@/modules/Public/pages/TermsAndConditionsPage"),
  }
];
